import {
  signInWithEmailAndPassword, 
  sendPasswordResetEmail, 
  signOut, 
  signInWithRedirect, 
  signInWithPopup, 
  createUserWithEmailAndPassword,
  OAuthProvider,
  getAuth
} from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import { db, auth, googleAuthProvider, facebookAuthProvider } from 'auth/FirebaseAuth';
import logError from '../utils/logger';


const FirebaseService = {
  signInEmailRequest: async (email, password) => 
    signInWithEmailAndPassword(auth, email, password)
      .then(user => user)
      .catch(err => err),

  forgetPassword: async (email) => 
    sendPasswordResetEmail(auth, email)
      .then(user => user)
      .catch(err => err),

  signOutRequest: async () => 
    signOut(auth)
      .then(user => user)
      .catch(err => err),

  signInOpenIdRequest: async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      // User is signed in.
      // IdP data available using getAdditionalUserInfo(result)
      // Get the OAuth access token and ID Token
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
      return result;
    } catch (error) {
      // Handle error.
      //Enviar error a log
      logError(error);
      console.error("Error during OpenID sign-in:", error);
      return { message: error.message, code: error.code };
    }
  },

  signInGoogleRequest: async () => 
    signInWithPopup(auth, googleAuthProvider)
      .then(user => user)
      .catch(err => err),

  signInFacebookRequest: async () => 
    signInWithPopup(auth, facebookAuthProvider)
      .then(user => user)
      .catch(err => err),

  signUpEmailRequest: async (email, password) => 
    createUserWithEmailAndPassword(auth, email, password)
      .then(user => user)
      .catch(err => err),

  // Llamadas a DB
  getPrimaryUserData: async (uid) => {
    try {
      // logError(uid)
      const snap = await get(ref(db, `Clients/${uid}`));
      if (snap.exists()) {
        return snap;
      } else {
        throw new Error('No data available');
      }
    } catch (err) {
      logError(err);
      throw err;
    }
  },

  getSecondaryUserData: (secondary, uid) => 
    get(ref(getDatabase(secondary), `Usuarios/${uid}/Submenu`))
      .then(snap => snap)
      .catch(err => err),

  checkEmailInSecondaryDB: async (email, secondary) => {
    try {
      const cleanedEmail = email.replace(/[.#$[\]]/g, '');
      const snap = await get(ref(getDatabase(secondary), `Admision/${cleanedEmail}`));
      return snap.exists();
    } catch (err) {
      logError(err);
      throw err;
    }
  }
};

export default FirebaseService;
