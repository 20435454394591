

const logError = (error) => {
    const timestamp = Date.now();
    console.log(error)
    fetch(`https://duoc-logistica-default-rtdb.firebaseio.com/ErrorLog/${timestamp}.json`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        message: error
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log("Error registrado:", data))
      .catch((error) => console.error("Error al enviar datos:", error));
};

module.exports = logError;