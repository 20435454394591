// Firebase actualizado a la versión 9
const FirebaseConfig = {
  apiKey: "AIzaSyAtPJ1OoBcv21c4c_AD-yF3zfaQxhg6Jsk",
  authDomain: "reporteria-web.firebaseapp.com",
  databaseURL: "https://reporteria-web.firebaseio.com",
  projectId: "reporteria-web",
  storageBucket: "reporteria-web.appspot.com",
  messagingSenderId: "824245382613",
  appId: "1:824245382613:web:07b8790c74c43e52",
  measurementId: "G-LRZ6CML97Y"
};

export default FirebaseConfig

