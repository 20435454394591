// Firebase actualizado a la versión 9
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, GithubAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import firebaseConfig from "configs/FirebaseConfig";

// Inicializar Firebase si no hay una instancia existente
const app = getApps().length ? getApp() : initializeApp(firebaseConfig);

// Firebase utils
const auth = getAuth(app);
const currentUser = auth.currentUser;
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const twitterAuthProvider = new TwitterAuthProvider();
const githubAuthProvider = new GithubAuthProvider();
const dbCloudFirestore = getFirestore(app);
const db = getDatabase(app);

export {
    db,
    auth,
    currentUser,
    googleAuthProvider,
    facebookAuthProvider,
    twitterAuthProvider,
    githubAuthProvider,
    dbCloudFirestore
};